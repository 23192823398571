<template>
    <div>
        <Loader v-if="fileIsUploading"/>
        <st-modal
            ref='modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="lg"
            :title="$t('APPLICATION.UPLOAD_DOCUMENTS_MODAL.TITLE')"
            customClass="form-modal"
            :id="modalId"
        >
            <template #body>
                <div>
                    <st-file
                        @change="addDocuments">
                        <template #buttons>
                            <st-button
                                class="px-6 mt-4 ml-6 st-btn-upload"
                                :callback="saveDocuments"
                                :disabled="fileIsUploading || !uploadedDocuments.length"
                                variant="primary">
                                <span>{{ $t('GENERAL.BUTTON.SAVE')}}</span>
                            </st-button>
                        </template>
                    </st-file>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { generateRandomId } from '@/core/helpers/globalMethods';


export default {
    name: 'ApplicationDocumentsUploadModal',
    components: {

    },
    props: {
        applicationId: {
            type: String,
            required: true
        },
        isPublic: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modalId: `st-modal-document-upload-${generateRandomId()}`,
            uploadedDocuments: []
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
            uploadAccessToken: 'applications/form/uploadAccessToken',
            record: 'applications/form/record',
            docs: 'applications/form/docs',
        }),
        fileIsUploading() {
            return this.loading['applications/upload'];
        }
    },
    methods: {
        ...mapActions({
            doUpload: 'applications/form/upload',
            doUpdate: 'applications/form/update',
            doFind: 'applications/form/find',
        }),
        show() {
            this.$refs['modal'].show();
        },
        hide(){
            this.$refs['modal'].hide();
        },
        async addDocuments(documents){
            this.uploadedDocuments = [];
            
            if (this.applicationId && documents?.length) {
                for (let i = 0; i < documents?.length; i++) {
                    this.uploadedDocuments.push(documents[i]);
                }
            }
        },
        async saveDocuments(){
            if (this.uploadedDocuments?.length) {
                const formData = new FormData();

                for (let i = 0; i < this.uploadedDocuments?.length; i++) {
                    const file = this.uploadedDocuments[i];
                    if (file instanceof File) {
                        formData.append('files', file);
                    }
                }

                const uploadedFiles = await this.doUpload({
                    files: formData,
                    uploadAccessToken: this.uploadAccessToken,
                });
                
                const attachedFiles = uploadedFiles.map((el) => ({
                    file_id: el.id,
                    filename: el.initialFilename,
                    created_date: el.created_date,
                    file_type: this.isPublic ? 'input' : 'output',
                    created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                    is_public: this.isPublic
                }));

                const payload = {
                    id: this.applicationId,
                    loadStaff: true,
                    values: {
                        files: [
                            ...this.docs,
                            ...attachedFiles,
                        ],
                    },
                };

                await this.doUpdate(payload);

                this.hide();
                this.$emit("uploaded");
            }
        }
    }
}
</script>
